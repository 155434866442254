@font-face {
	font-family: "HelveticaBlack";
	src: url("./fonts/HelveticaBlack/HelveticaBlack.eot");
	src: url("./fonts/HelveticaBlack/HelveticaBlack.eot?#iefix")format("embedded-opentype"),
	url("./fonts/HelveticaBlack/HelveticaBlack.woff") format("woff"),
	url("./fonts/HelveticaBlack/HelveticaBlack.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

/* font-family: "HelveticaBold"; */
@font-face {
	font-family: "HelveticaBold";
	src: url("./fonts/HelveticaBold/HelveticaBold.eot");
	src: url("./fonts/HelveticaBold/HelveticaBold.eot?#iefix")format("embedded-opentype"),
	url("./fonts/HelveticaBold/HelveticaBold.woff") format("woff"),
	url("./fonts/HelveticaBold/HelveticaBold.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

/* font-family: "HelveticaLight"; */
@font-face {
	font-family: "HelveticaLight";
	src: url("./fonts/HelveticaLight/HelveticaLight.eot");
	src: url("./fonts/HelveticaLight/HelveticaLight.eot?#iefix")format("embedded-opentype"),
	url("./fonts/HelveticaLight/HelveticaLight.woff") format("woff"),
	url("./fonts/HelveticaLight/HelveticaLight.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

/* font-family: "HelveticaMedium"; */
@font-face {
	font-family: "HelveticaMedium";
	src: url("./fonts/HelveticaMedium/HelveticaMedium.eot");
	src: url("./fonts/HelveticaMedium/HelveticaMedium.eot?#iefix")format("embedded-opentype"),
	url("./fonts/HelveticaMedium/HelveticaMedium.woff") format("woff"),
	url("./fonts/HelveticaMedium/HelveticaMedium.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

/* font-family: "HelveticaRegular"; */
@font-face {
	font-family: "HelveticaRegular";
	src: url("./fonts/HelveticaRegular/HelveticaRegular.eot");
	src: url("./fonts/HelveticaRegular/HelveticaRegular.eot?#iefix")format("embedded-opentype"),
	url("./fonts/HelveticaRegular/HelveticaRegular.woff") format("woff"),
	url("./fonts/HelveticaRegular/HelveticaRegular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

