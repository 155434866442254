@import '../node_modules/reset-css/reset.css';
@import './fonts.css';
.menu {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 5;
}

/* --------

	variables

                   -------- */

:root {
	--white-background: #fff;
	--default-color: #000;
	--primary-color: #1C93DA;
	--color-message: #C83A1B;
	--error-text: #d00000;
	--form-water-color: #707070;
	--primary-button-color: #1C93DA;
	--primary-button-thank: #69D619;
	--primary-button-onwards: #FEFEFE;
	--border-color-input: #96BACC;
	--helvetica-regular: "HelveticaRegular", sans-serif;
	--helvetica-bold: "HelveticaBold", sans-serif;
}

/* --------

	BUTTON STYLE

                   -------- */

input:focus,
input:active,
button{
	outline-style: none;
}

button,
input[type='submit']{
	cursor: pointer;
}

.home-form_container input[type="number"]{
	-moz-box-sizing:    border-box;
	-webkit-box-sizing: border-box;
	 box-sizing:        border-box;
}

.show-error {
	display: block;
}

.hide-error {
	display: none;
}

.water-form-error {
	margin-bottom: 20px;
    color: var(--color-message);
    text-align: center;
    font-size: 16px;
    font-family: var(--helvetica-regular);
}
.thank-you-link {
	background: none;
	border: none;
	margin-top: 20px;
	font-size: 14px;
	color: #707070;
	font-weight: bold;
}
.thank-button,
.ready-button,
.report-button,
.closed-button{
	width: 170px;
	height: 63px;
	margin: 0 auto;
	background: var(--primary-button-thank);
	color: var(--primary-button-onwards);
	font-family: var(--helvetica-bold);
	font-size: 19px;
	text-transform: uppercase;
	border: none;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 10px 0 rgba(85,191,7,0.4);
	-moz-box-shadow: 0 6px 10px 0 rgba(85,191,7,0.4);
	box-shadow: 0 6px 10px 0 rgba(85,191,7,0.4);
	text-decoration: none;
	line-height: 63px;
}

.ready-button,
.report-button,
.closed-button{
	background: var(--primary-button-color);
	-webkit-box-shadow: 0 6px 10px 0 rgba(33,107,145,.40);
	-moz-box-shadow: 0 6px 10px 0 rgba(33,107,145,.40);
	box-shadow: 0 6px 10px 0 rgba(33,107,145,.40);
	text-transform: capitalize;
}

.report-button {
	display: block;
	margin: 0 auto;
	margin-bottom: 10px;
}

.report-button.disabled {
	background: #ccc;
}

.water-form__input-submit input {
	width: 100%;
	height: 63px;
	background: var(--primary-button-color);
	text-align: center;
	color: var(--primary-button-onwards);
	font-family: var(--helvetica-bold);
	font-size: 19px;
	border: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
	-moz-box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
	box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
}


/* --------

	BACKGROUND PAGE STYLE

                   -------- */

.home-background,
.thank-background,
.water-background,
.report-background,
.ready-background,
.payment-background,
.closed-background{
	position: relative;
	/*padding-top: 10px;*/
	min-height: 100vh;
	display: flex;
	align-items: center;
	background: url('./images/background-home.jpg')no-repeat center/cover;
	overflow: hidden;
}


/* --------

	CONTAINER PAGE STYLE

                   -------- */

.home-container,
.thank-container,
.water-container,
.report-container,
.ready-container,
.payment-container,
.closed-container{
	width: 100%;
	padding: 0 20px;
}

@media screen and (min-width: 767px) {
	.home-container,
	.thank-container,
	.water-container,
	.report-container,
	.ready-container,
	.payment-container,
	.closed-container {
		max-width: 700px;
		margin: 0 auto;
	}
}

.thank-content,
.ready-content,
.report-content,
.closed-content{
	position: relative;
	height: 332px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: var(--white-background);
	text-align: center;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	box-shadow: 0 6px 21px 0 rgba(33,107,145,0.15);
	-webkit-box-shadow: 0 6px 21px 0 rgba(33,107,145,0.15);
	-moz-box-shadow: 0 6px 21px 0 rgba(33,107,145,0.15);
}

.nearest-content {
	height: 100%;
	padding: 20px;
}

.nearest-content li{
	margin-bottom: 5px;
}
.nearest-content .thank-button{
	margin-top: 10px;
}

/* --------

	HOME PAGE STYLE

                   -------- */

.home-title {
	margin-bottom: 29px;
	text-align: center;
	color: var(--default-color);
	font-family: var(--helvetica-regular);
	font-size: 22px;
}

.home-advice {
	text-align: center;
	color: var(--primary-color);
	/* color: red; */
	font-family: var(--helvetica-regular);
	font-size: 20px;
	line-height: 1.5;
	margin-bottom: 50px;
}

.home-advice a {
	/* color: var(--primary-color); */
	color: var(--form-water-color);
}

@media screen and (min-width: 374px){
	.home-title {
		font-size: 24px;
	}
}

.home-form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.issue-btn,
.policy-btn {
	margin-top: 20px;
	line-height: 1.5;
}

.issue-btn,
.policy-btn {
	color: #7d7c7c;
	cursor: pointer;
    text-decoration: none;
	font-family: var(--helvetica-regular);
	text-shadow: 5px 5px 10px #fff;
    font-weight: bold;
}

.policy-btn a {
	color: var(--primary-button-color);
}

@media screen and (max-width: 380px){
	.home-form_container input,
	.home-form_container .home-qr--block div {
		font-size: 15px!important;
	}
}

.home-form_container input,
.home-form_container .home-qr--block div{
	width: 100%;
	height: 63px;
	font-family: var(--helvetica-regular);
	font-size: 17px;
	color: var(--primary-color);
	background: var(--white-background);
	border: 1px solid var(--border-color-input);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.home-qr--block div {
	text-align: center;
	line-height: 63px;
}

.home-form_container input::placeholder {
	color: var(--primary-color);
}

.home-next {
	width: 100%;
}

.home-next button,
.water-form__input-submit button
{
	display: block;
	width: 100%;
	height: 63px;
	background: var(--primary-button-color);
	text-align: center;
	color: #FEFEFE;
	font-family: var(--helvetica-bold);
	font-size: 19px;
	border: none;
	line-height: 63px;
	text-decoration: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
	-moz-box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
	box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
}

.home-screen--button {
	height: 35px;
	background: var(--primary-button-thank);
	color: #FEFEFE;
	font-family: var(--helvetica-bold);
	font-size: 14px;
	border: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0 6px 10px 0 rgba(85,191,7,0.4);
	-moz-box-shadow: 0 6px 10px 0 rgba(85,191,7,0.4);
	box-shadow: 0 6px 10px 0 rgba(85,191,7,0.4);
}

.home-form_container label {
	width: 100%;
}

.home-form_container label input {
	text-align: center;
}

.home-form_container label:first-of-type {
	margin-bottom: 8px;
}

.home-qr--block {
	width: 100%;
	position: relative;
	margin-bottom: 26px;
}

.home-qr--block:before {
	content: '';
	position: absolute;
	display: block;
	width: 24px;
	height: 24px;
	top: 50%;
	left: 20px;
	transform: translate(0%, -50%);
	background: url('./images/qr-icon.png');
}
.home-form_container span {
	margin-bottom: 12px;
	font-size: 13px;
	color: var(--primary-color);
	font-family: var(--helvetica-bold);
}

/* --------

	THANK YOU PAGE STYLE

                   -------- */

.thank-title {
	margin: 20px;
	color: var(--primary-color);
	font-family: var(--helvetica-regular);
	font-size: 20px;
	line-height: 1.5;
}

.thank-title.error {
	color: red!important;
}


/* --------

	WATER PAGE STYLE

                   -------- */

.water-title__address,
.ready-title,
.report-title,
.closed-title{
	position: relative;
	display: inline-block;
	margin-bottom: 14px;
	color: var(--primary-color);
	font-family: var(--helvetica-regular);
	font-size: 24px;
}

.water-title__address:before,
.ready-title:before,
.report-title:before,
.closed-title:before{
	content: '';
	position: absolute;
	left: -39px;
	top: 0;
	width: 18px;
	height: 24px;
	background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231C93DA;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M256,0C153.8,0,70.6,83.2,70.6,185.4c0,126.9,165.9,313.2,173,321c6.6,7.4,18.2,7.4,24.8,0 c7.1-7.9,173-194.1,173-321C441.4,83.2,358.2,0,256,0z M256,278.7c-51.4,0-93.3-41.9-93.3-93.3s41.9-93.3,93.3-93.3 s93.3,41.9,93.3,93.3S307.4,278.7,256,278.7z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center/cover;
}

.water-address,
.ready-address,
.report-address,
.closed-address{
	position: absolute;
	top: 70px;
	left: 0;
	width: 100%;
	text-align: center;
}

@media (max-height: 400px) {
	.water-address,
	.ready-address,
	.report-address,
	.closed-address{
		position: initial;
		text-align: center;
	}
}


@media (max-width: 420px) {
	.water-location__address span{
		font-size: 13px!important;
		word-wrap: break-spaces;
	}
}

.water-location__address span,
.ready-location__address span,
.report-location__address span,
.closed-location__address span{
	color: var(--default-color);
	font-family: var(--helvetica-bold);
	font-size: 15px;
}

.water-form__title {
	margin-bottom: 29px;
	color: var(--default-color);
	text-align: center;
	font-size: 22px;
	font-family: var(--helvetica-regular);
}

.water-text--error {
	display: none;
	margin-bottom: 15px;
	color: var(--error-text);
	text-align: center;
	font-size: 16px;
	font-family: var(--helvetica-regular);
}

@media screen and (min-width: 374px) {
	.water-form__title {
		font-size: 24px;
	}
}

.water-form__row {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}

.water-form__input {
	position: relative;
	width: 50%;
	/* padding-left: 20px; */
	/* padding-right: 20px; */
}

.water-form__input.input-liters {
	padding-right: 10px;
}

.water-form__input.input-price {
	padding-left: 10px;
}



/* @media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
	.water-form__input.input-price{
		margin-right: 15px;
	}
} */

.water-form__input.input-error input{
	color: red;
	border-color: red;
}

.water-form__input.input-error:before {
	color: red !important;
}

.water-form__input:first-of-type {
	padding-left: 0;
}

.water-form__input:first-of-type::before {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translate(0, -50%);
	color: var(--form-water-color);
	font-family: var(--helvetica-regular);
	font-size: 24px;
}

.water-form__input:last-of-type::before {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translate(0, -50%);
	color: var(--form-water-color);
	font-family: var(--helvetica-regular);
	font-size: 24px;
}

.water-form__input input {
	width: 100%;
	height: 63px;
	padding-left: 20px;
	color: var(--form-water-color);
	font-family: var(--helvetica-regular);
	font-size: 24px;
	background: var(--white-background);
	border: 1px solid var(--border-color-input);
	text-align: left;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.water-form__input input::placeholder {
	color: var(--form-water-color);
	font-family: var(--helvetica-regular);
	font-size: 24px;
}



/* --------

	READY PAGE STYLE

                   -------- */

.ready-title__content {
	/*width: 180px;*/
	display: block;
	margin: 10px 26px 10px 26px;
	line-height: 24px;
	color: var(--primary-color);
	font-family: var(--helvetica-regular);
	font-size: 19px;
}

.ready-address {
	position: absolute;
	left: 0;
	top: 60px;
	width: 100%;
	text-align: center;
}

/* --------

	REPORT PAGE STYLE


                   -------- */

.second-box p {
	text-align: center;
	color: var(--primary-color);
	margin-top: 10px;
}

.closed-content{
	height: 380px;
	padding: 20px 0px;
}

.report-content {
	height: 420px;
}

.report-content .home-form_container input{
	padding-left: 10px;
}

.report-form {
	text-align: left;
	padding: 0 20px;
}

.report-form textarea {
	border: 2px solid var(--primary-button-color);
	border-radius: 10px;
	width: 100%;
	height: 100px;
	box-shadow: 0px 0px 4px #000;
	outline: none;
	padding: 5px 10px;
}

.report-form .hidden {
	display: none;
}

.report-form div {
	margin-bottom: 27px;
}

/* .report-form div:last-of-type {
	margin-bottom: 46px;
} */

.report-message {
	color: var(--default-color);
	font-family: var(--helvetica-regular);
	font-size: 17px;
	line-height: 20px;
}

.or-span {
	margin-bottom: 20px;
	font-size: 13px;
	color: var(--primary-color);
	font-family: var(--helvetica-bold);
}

@media screen and (min-width: 320px) and (max-width: 374px) {
	.report-content,
	.closed-content{
		height: 290px;
	}
	.report-form div {
		margin-bottom: 10px;
	}
	.report-form div:last-of-type {
		margin-bottom: 10px;
	}
	.report-message {
		font-size: 14px;
	}
}

.report-input {
	display: flex;
	align-items: center;
}

.report-input span{
	display: block;
}

.report-input input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	margin: 10px 0 0 7px;
}
.report-message {
	position: relative;
	padding: 0 0 0 55px;
	cursor: pointer;
}
.report-message:before {
	content: '';
	position: absolute;
	top: -5px;
	left: 0;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #EDF4F8;
}
.report-message:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 7px;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #1C93DA;
	opacity: 0;
	transition: .2s;
}

.report-input input:checked + .report-message {
	color: #1C93DA;
}

.report-input input:checked + .report-message:after {
	opacity: 1;
}

.contact-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.contact-form-title {
	margin: 10px;
	text-align: center;
	color: var(--default-color);
	font-family: var(--helvetica-regular);
	font-size: 22px;
}

.contact-form input {
	width: 90%;
	height: 50px;
	margin-bottom: 30px;
	text-align: center;
	font-family: var(--helvetica-regular);
	font-size: 17px;
	color: var(--primary-color);
	background: var(--white-background);
	border: 1px solid var(--border-color-input);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.contact-form input::placeholder {
	color: var(--primary-color);
	font-size: 15px;
}

/* --------

	CLOSED PAGE STYLE


                   -------- */

.closed-content {
	position: relative;
	justify-content: flex-start;
}

.closed-content--message {
	margin-top: 52px;
	margin-bottom: 29px;
	padding: 0 41px;
}

@media screen and (min-width: 320px) and  (max-width: 374px) {
	.closed-content--message {
		margin-top: 40px;
		padding: 0 20px;
	}
}

.closed-content--text:first-of-type {
	margin-bottom: 32px;
}

.closed-content--text {
	color: var(--color-message);
	font-family: var(--helvetica-regular);
	font-size: 20px;
	line-height: 24px;
}

@media screen and (min-width: 320px) and  (max-width: 374px) {
	.closed-content--text {
		font-size: 14px;
	}
}

.closed-location--text {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 30px;
}

.closed-location--text span {
	position: relative;
	color: var(--primary-color);
	font-family: var(--helvetica-regular);
	font-size: 19px;
}

.closed-location--text span:before {
	content: '';
	position: absolute;
	left: -25px;
	top: -7px;
	width: 18px;
	height: 24px;
	background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231C93DA;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M256,0C153.8,0,70.6,83.2,70.6,185.4c0,126.9,165.9,313.2,173,321c6.6,7.4,18.2,7.4,24.8,0 c7.1-7.9,173-194.1,173-321C441.4,83.2,358.2,0,256,0z M256,278.7c-51.4,0-93.3-41.9-93.3-93.3s41.9-93.3,93.3-93.3 s93.3,41.9,93.3,93.3S307.4,278.7,256,278.7z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center/cover;
}

@media screen and (min-width: 320px) and  (max-width: 374px) {
	.closed-location--text span {
		font-size: 14px;
	}
}

/* --------

	PAYMENT FORM STYLE

                   -------- */

.payment-price--block {
	position: absolute;
	left: 0;
	top: 20px;
	width: 100%;
}

.back-block {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background: var(--white-background);
	margin-left: 20px;
	margin-bottom: 22px;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
	.back-block {
		margin-bottom: 0;
	}
}

.back-icon {
	display: block;
	width: 16px;
	height: 16px;
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 408 408' style='enable-background:new 0 0 408 408;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231C93DA;%7D%0A%3C/style%3E%3Cg%3E%3Cg id='arrow-back'%3E%3Cpath class='st0' d='M408,178.5H96.9L239.7,35.7L204,0L0,204l204,204l35.7-35.7L96.9,229.5H408V178.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
	object-fit: cover;
	text-indent: -9999999px;
}

.price-block {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
}

.payment-price {
	color: var(--default-color);
	font-size: 50px;
	line-height: 60px;
	font-family: var(--helvetica-regular);
}

.payment-text {
	font-size: 14px;
	font-family: var(--helvetica-bold);
	color: var(--primary-color);
}

.field-row {
	display: flex;
	flex-direction: row;
}
.field-card,
.field-validity,
.form-control,
.field-cvv{
	display: flex;
	flex-direction: column;
}

.field-cvv {
	margin-left: 11px;
}

.filed-label {
	color: #a3d7f6;
	margin-bottom: 9px;
	font-family:var(--helvetica-bold);
	font-size: 13px;
}

.field-card {
	margin-bottom: 16px;
}

.field-card input {
	height: 48px;
}

.field-validity input {
	width: 138px;
	height: 48px;
}

.field-cvv input {
	width: 74px;
	height: 48px;
}

.field-card input,
.field-validity input,
.field-cvv input {
	background: var(--white-background);
	color: var(--default-color);
	font-size: 18px;
	text-align: center;
	border: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.field-card input::placeholder,
.field-validity input::placeholder,
.field-cvv input::placeholder {
	font-size: 18px;
	color: var(--default-color);
}

.payment-form--background {
	margin-bottom: 20px;
	padding: 16px 15px;
	background-image: linear-gradient(to bottom, #1c93da, #289adf, #33a0e3, #3da7e8, #46aeec);
	-webkit-box-shadow: 3px 5px 10px 0 rgba(33,107,145,0.4);
	-moz-box-shadow: 3px 5px 10px 0 rgba(33,107,145,0.4);
	box-shadow: 3px 5px 10px 0 rgba(33,107,145,0.4);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.row-submit {
	width: 100%;
}

.row-submit button {
	width: 100%;
	height: 63px;
	background: #69d619;
	color: #FEFEFE;
	font-size: 19px;
	border: none;
	font-family: var(--helvetica-bold);
	text-align: center;
	-webkit-box-shadow: 4px 5px 10px 0 rgba(33,107,145,0.35);
	-moz-box-shadow: 4px 5px 10px 0 rgba(33,107,145,0.35);
	box-shadow: 4px 5px 10px 0 rgba(33,107,145,0.35);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

/* --------

	HOME SCREEN FORM STYLE

                   -------- */

.home-screen--block {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 15px 0;
	background: var(--white-background);
}

.home-screen--block.home-screen--none {
	display: none;
}

/* --------

	LOADER SCREEN FORM STYLE

                   -------- */

div[aria-busy="true"]{
	position: absolute;
	z-index: 1;
	background: #fff;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-btn {
	position: absolute;
	top: 20px;
	left: 20px;
	color: var(--primary-color);
}
.home-btn a{
	color: var(--primary-color);
}

@media (max-height: 420px){
	.lang-switch {
		display: none!important;
	}
}

.lang-switch button {
	color: var(--primary-color);
	background: none;
	border:none;
	text-decoration: none;
	font-family: var(--helvetica-regular);
}

.lang-switch button.active {
	color: var(--form-water-color);
}

.vodomatId-box-btn {
	background: var(--primary-button-color);
	padding: 15px 25px;
	color: #fff;
	font-size: 12px;
	border: none;
	border-radius: 4px;
	position: absolute;
	right: 25px;
}

.vodomatId-box-btn-edit {
	background: var(--primary-button-color);
	padding: 10px 20px;
	color: #fff;
	font-size: 12px;
	border: none;
	border-radius: 4px;
	margin-left: 20px;
}
.report__vodomat-number p {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
}
