@import url(./temp.css);

.App {
    padding: 0;
}

.HeaderOpaque {
    background-color: #FEFEFE;
}

.MenuButton {
    background-color: var(--primary-button-color);
    border: none;
}

.MenuItemButton {;
	width: 100%;
	/* height: 63px; */
	background: var(--primary-button-color);
	text-align: center;
	color: #FEFEFE;
	font-family: var(--helvetica-bold);
	font-size: 19px;
	border: none;
	line-height: 63px;
	text-decoration: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
	-moz-box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
	box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
}

/* BODY ---------------------------------------------------------- */
.Body {
    background: url('./images/background-home.jpg')no-repeat center/cover;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0;
}
/* END BODY ------------------------------------------------------ */

/* READY --------------------------------------------------------- */
.ReadyMessage {
    font-size: 15px;
    color: red;
    /*color: #7d7c7c;*/
	font-family: var(--helvetica-regular);
	text-shadow: 5px 5px 10px #fff;
    font-weight: bold;
}

.ReadyButton {
    margin: auto 26px;
    color: #FEFEFE;
    font-family: var(--helvetica-bold);
    font-size: 19px;
    line-height: 63px;
    border: none;
    -webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
	-moz-box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
	box-shadow: 3px 5px 10px 0 rgba(33,107,145,.40);
}

.ReadyButtonYes {
    background: var(--primary-button-color);
}

.ReadyButtonNo {
    background: var(--form-water-color);
}
/* END READY --------------------------------------------------------- */

/* STATIC PAGES ------------------------------------------------- */
.Document {
    padding-top: 64px;
}

.StaticPageContent {
    padding-top: 64px;
    font-family: var(--helvetica-regular);
}

.StaticPageContent .card-title {
    color: #333;
    font-weight: 600;
    font-size: 24px; 
    margin-bottom: 12px;
}

.StaticPageContent .card-subtitle {
    color: #555;
    font-weight: 500;
    font-size: 16px; 
    margin-bottom: 8px;
}

.StaticPageContent .card-text, .list-group {
    color: #666;
    font-size: 14px; 
    line-height: 1.5;
    border: none !important;
}

.StaticPageContent a {
    color: var(--primary-color)
}

.StaticPageContent .list-group-item {
    border: none !important;
}
/* END STATIC PAGES ----------------------------------------------- */

/* FOOTER --------------------------------------------------------- */
.Footer {
    z-index: 999;
}

.Footer img {
    clip-path: inset(3px 3px 3px 3px);
}

/* END FOOTER ----------------------------------------------------- */
